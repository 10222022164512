import './src/styles/global.css';
import React, { useState } from 'react';
import { Script } from 'gatsby';
import Layout from './src/components/layout-component/layout';

//* Wraps every page in a component
export const wrapPageElement = ({ element }) => {
  return (
    <>
      {process.env.NODE_ENV === 'development' ? null : (
        <Script id="script-test" src="/resources/package/index.js"></Script>
      )}
      <Layout pages={[]} footer={{ footerNavigations: [] }}>
        {element}
      </Layout>
    </>
  );
};

export const shouldUpdateScroll = () => false;
