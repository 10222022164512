import { navigate, Script } from 'gatsby';
import { useEffect, useState } from 'react';
import * as React from 'react';

const Layout = ({ children, pages, footer }: any) => {
  const homePage = '/';

  const [scriptLoaded, setScriptLoaded] = useState(process.env.GATSBY_AZURE_SLOT !== 'prod');
  const handleNavigate = function (e: CustomEvent) {
    if (e.detail.url) {
      navigate(e.detail.url);
    }
  };

  const imprint = [
    {
      pageTitle: 'Impressum',
      navigation: '/Impressum',
    },
    {
      pageTitle: 'Datenschutz',
      navigation: '/Datenschutz',
    },
    {
      pageTitle: 'AGB',
      navigation: '/agb',
    },
    {
      pageTitle: 'EDEKA Minden-Hannover',
    },
  ];

  useEffect(() => {
    if (window) {
      window.addEventListener('navigate', handleNavigate);
    }

    if (process.env.NODE_ENV === 'development') {
      import('../../../../../../edk-mi-roa-app-storybook/dist/package').then(() => setScriptLoaded(true));
    }
  });

  const logo = `/resources/img/logos/its_logo.svg`;

  // TODO: check how rel="preload" works
  return (
    <main style={{ display: 'flex', flexDirection: 'column' }}>
      <link rel="stylesheet" id="css-theme" href={`its-theme.css`}></link>
      {process.env.NODE_ENV === 'development' ? null : (
        <Script
          id="webcomponent-script"
          src="/resources/package/index.js"
          onLoad={() => setScriptLoaded(true)}
        ></Script>
      )}
      {scriptLoaded ? (
        <>
          <edk-header
            logo={logo}
            navlinktext="Startseite"
            navlinkurl={homePage}
            navigations={JSON.stringify(buildHeaderNaigation(pages))}
          ></edk-header>
          <div>{children}</div>
          <edk-footer
            columns={buildFooterNavigations(footer)}
            socials={buildSocials(footer)}
            bottommenu={buildImprintFooter(imprint)}
          ></edk-footer>
        </>
      ) : null}
    </main>
  );
};

export default Layout;

function buildHeaderNaigation(pages: any) {
  const headerContent: any = {};
  if (pages) {
    for (const page of pages) {
      const subNavs = [];

      if (!page.subPage && !page.startPage) {
        for (const subNav of page.subPages) {
          if (subNav.related_sites_id) {
            subNavs.push({
              name: subNav.related_sites_id.pageTitle,
              url: subNav.related_sites_id.navigation,
            });
          }
        }
        if (subNavs.length > 0) {
          headerContent[page.pageTitle] = subNavs;
        } else {
          headerContent[page.pageTitle] = page.navigation;
        }
      }
    }
  }
  return headerContent;
}

function buildSocials(footer: any) {
  const socials: any = {};

  if (footer.instagramUrl) {
    socials.Instagram = footer.instagramUrl;
  }
  if (footer.facebookUrl) {
    socials.Facebook = footer.facebookUrl;
  }
  if (footer.xingUrl) {
    socials.Xing = footer.xingUrl;
  }
  if (footer.linkedinUrl) {
    socials.LinkedIn = footer.linkedinUrl;
  }
  if (footer.youtubeUrl) {
    socials.YouTube = footer.youtubeUrl;
  }
  if (footer.twitterUrl) {
    socials.Twitter = footer.twitterUrl;
  }
  return JSON.stringify(socials);
}

function buildImprintFooter(footer: any) {
  const imprints: any = {};

  for (const impNav of footer) {
    imprints[impNav.pageTitle] = impNav.navigation ? impNav.navigation : '';
  }
  return JSON.stringify(imprints);
}

function buildFooterNavigations(footer: any) {
  const footerNavs: any = {};

  for (const footerNavWrap of footer.footerNavigations) {
    const footerNav = footerNavWrap.footerNavigations_id;
    if (footerNav.categoryName && footerNav.navigations) {
      const navs: any = {};

      for (const navWrap of footerNav.navigations) {
        const nav = navWrap.sites_id;

        if (process.env.GATSBY_AZURE_SLOT == 'prod') {
          if (nav.stage == 'production') {
            navs[nav.pageTitle] = nav.navigation;
          }
        } else {
          if (nav) {
            navs[nav.pageTitle] = nav.navigation;
          }
        }
      }

      footerNavs[footerNav.categoryName] = navs;
    }
  }
  return JSON.stringify(footerNavs);
}
